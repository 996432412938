import $ from 'jquery'
import { TweenMax, TimelineMax, Linear } from 'gsap'
import ScrollMagic from 'scrollmagic'
import Util from '../util'


const NAME = 'intro'
const DATA_KEY = 'wr.intro'
const JQUERY_NO_CONFLICT = $.fn[NAME]

function getIntroWidth() {
    return $(window).width();
}

function getIntroHeight() {
    const width = getIntroWidth();
    var height = width * 0.8;
    if (width > 576) {
        height = width * 0.57125;
    }
    const max = width * 0.9;
    return Math.min(height, max);
}

class IntroSceneTop {
    constructor(element) {
        this._element = element;
        this._duration = 0.7;
        var tween = TweenMax.to('#intro-animation-top', 0.2, { css: { scale: 0.8, opacity: 0, top: '-15%'}, ease: Linear.easeNone });
        this.scene = new ScrollMagic.Scene({
            triggerElement: this._element,
            triggerHook: 0,
            offset: 5,
            duration: getIntroHeight() * this._duration
        })
            .setPin(this._element, {pushFollowers: false})
            .setTween(tween)
            .on('leave', this._onLeave.bind(this))
            .addTo(Util.getScrollController());
    }

    _onLeave(event) {
        if (event.state.toLowerCase() === 'before') {
            $('#intro-animation-top', this._element).removeAttr('style');
        }
    }

    update() {
        var height = getIntroHeight();
        var width = getIntroWidth();
        var $element = $(this._element);
        this.scene.duration(height * this._duration);
        this.scene.update(true);
        $element
            .css('width', width)
            .css('height', height)
            .parent()
            .css('width', width)
            .css('min-width', width)
            .css('min-height', height)
            .css('height', height)
        this.scene.refresh();
    }
}

class IntroSceneMiddle {
    constructor(element) {
        this._element = element;
        this._duration = 0.2;
        var leftTween = TweenMax.to('#intro-animation-left', 0.2, { css: { opacity: 0, top: '57.5%' }, ease: Linear.easeNone });
        // var rightTween = TweenMax.to('#intro-animation-right', { css: { opacity: 0, bottom: '25%' }, ease: Linear.easeNone });
        this.scene = new ScrollMagic.Scene({
            triggerElement: this._element,
            triggerHook: 0,
            offset: 5,
            duration: getIntroHeight() * this._duration
        })
            .setTween(leftTween)
            .on('leave', this._onLeave.bind(this))
            .addTo(Util.getScrollController());
    }

    _onLeave(event) {
        if (event.state.toLowerCase() === 'before') {
            $('#intro-animation-left, #intro-animation-right', this._element).removeAttr('style');
        }
    }

    update() {
        this.scene.duration(getIntroHeight() * this._duration);
        this.scene.update(true);
    }
}

class IntroSceneBottom {
    constructor(element) {
        this._element = element
        this.scene = new ScrollMagic.Scene({
            triggerElement: this._element,
            triggerHook: 0,
            offset: 5,
        })
            .setClassToggle('#intro-animation-bottom', 'is-hidden')
            .addTo(Util.getScrollController());
    }
}

class Intro {
    constructor(element) {
        this._element = element;
        this._scenes = [new IntroSceneTop(element), new IntroSceneMiddle(element), new IntroSceneBottom(element)];
        this._onResize();
        $(window).on('resize', this._onResize.bind(this));
        $('#intro-animation-bottom', this._element).on('click', this._onPressArrowDown.bind(this));
    }

    _onPressArrowDown(event) {
        var $target = $($(event.currentTarget).attr('href'));
        if ($target.length) {
            event.preventDefault();
            Util.scrollBodyTo($target.offset().top);
            return false;
        }
    }

    _onResize() {
        this._scenes.forEach(function(scene) {
            if (typeof scene.update === 'function') {
                scene.update();
            }
        });
    }

    static _jQueryInterface(config) {
        return this.each(function () {
            const $element = $(this)
            let data = $element.data(DATA_KEY)

            if (!data) {
            data = new Intro(this)
            $element.data(DATA_KEY, data)
            }

            if (config === 'close') {
            data[config](this)
            }
        })
    }
}

$.fn[NAME] = Intro._jQueryInterface
$.fn[NAME].Constructor = Intro
$.fn[NAME].noConflict = () => {
  $.fn[NAME] = JQUERY_NO_CONFLICT
  return Intro._jQueryInterface
}
$(function() {
    // $('.component-intro').intro();
});
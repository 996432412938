import $ from 'jquery'
import 'jquery-validation/dist/jquery.validate.js'
// import 'materialize-css/dist/css/materialize'
// import 'materialize-css'
import './materialize'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

// Initialize Firebase
firebase.initializeApp({
    apiKey: "AIzaSyBZS5Xeag-QXUqFBwGgI3fqwhu51HeW8M4",
    authDomain: "webrectrix.firebaseapp.com",
    databaseURL: "https://webrectrix.firebaseio.com",
    projectId: "webrectrix",
    storageBucket: "webrectrix.appspot.com",
    messagingSenderId: "131236381101",
    appId: "1:131236381101:web:48859aa55bbb3212b22124",
    measurementId: "G-1PCE71Y64M"
})

var db = firebase.firestore()

$(function() {
    $('a', '#form-contact').on('click', function(event) {
        event.preventDefault();
        $('#form-contact').removeClass('is-success is-error');
        return false;
    });

    $('#form-contact').validate({
        rules: {
            name: {
                required: true,
                minlength: 3
            },
            email: {
                required: true,
                email: true
            },
            message: {
                required: true,
                minlength: 15
            }
        },
        //For custom messages
        messages: {
            name: {
                required: 'Please enter your name',
                minlength: 'Enter at least 3 characters'
            },
            email: {
                email: 'Please enter a valid email'
            }
        },
        errorClass: 'invalid',
        validClass: 'valid',
        errorElement: 'div',
        errorPlacement: function (error, element) {
            $(element).closest('.input-field')
                        .find('input[type=text],input[type=email],textarea,select')
                        .filter(':visible:first')
                        .next()
                        .after(error);
        },
        submitHandler: function (form) {
            db.collection('messages').add($(form).serializeObject())
            .then(function(docRef) {
                $('#form-contact').addClass('is-success').get(0).reset();
                console.log('Document written with ID: ', docRef.id);
            })
            .catch(function(error) {
                $('#form-contact').addClass('is-error')
                console.error('Error adding document: ', error);
            })
            return false
        },
        invalidHandler: function(event, validator) {

        }
    });
});

$.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};
import $ from 'jquery'
import ScrollMagic from 'scrollmagic'
import { TweenMax, TimelineMax, Linear } from 'gsap'
import Util from '../util'

const NAME = 'work_carousel'
const DATA_KEY = 'wr.work_carousel'
const JQUERY_NO_CONFLICT = $.fn[NAME]

class WorkCarousel {
    constructor(element) {
        this._element = element;
        this._duration = 0.45;
        this._setMaxHeight();
        var tween = TweenMax.to('.work-content', 0.2, { css: { opacity: 1, left: 0 }, ease: Linear.easeNone });
        this.scene = new ScrollMagic.Scene({
            triggerElement: this._element,
            triggerHook: 0.7,
            offset: 5,
            duration: this._getMaxHeight() * this._duration
        })
        .setTween(tween)
        .addTo(Util.getScrollController());

        $(window).on('resize', this._onResize.bind(this));
    }

    _onResize() {
        this._setMaxHeight();
        this.scene.duration(this._getMaxHeight() * this._duration);
        this.scene.update(true);
    }

    _setMaxHeight() {
        const height = this._getMaxHeight();
        $('.carousel-inner', this._element).height(height);
    }

    _getMaxHeight() {
        const itemsHeights = $('.carousel-item', this._element).map(function() {
            return $(this).height();
        }).toArray();
        return Math.max(...itemsHeights);
    }

    static _jQueryInterface(config) {
        return this.each(function () {
            const $element = $(this)
            let data = $element.data(DATA_KEY)

            if (!data) {
            data = new WorkCarousel(this)
            $element.data(DATA_KEY, data)
            }

            if (config === 'close') {
            data[config](this)
            }
        })
    }
}

$.fn[NAME] = WorkCarousel._jQueryInterface
$.fn[NAME].Constructor = WorkCarousel
$.fn[NAME].noConflict = () => {
  $.fn[NAME] = JQUERY_NO_CONFLICT
  return WorkCarousel._jQueryInterface
}
$(function() {
    $('#work-carousel').work_carousel();
});
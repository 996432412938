import $ from 'jquery'
import { MathUtils, Vector3 } from 'three'

export default class {
    constructor(element) {
        this.element = element;

        this.scrollY = 0;
        this.prevScrollY = 0;

        this.onResize();

        this.delay = 50;

        $('.component-3d').on('mousemove', this._onHover.bind(this));
        $(document).on('scroll', this._onScroll.bind(this));
    }

    update() {
        if (!this.model) {
            return;
        }
        if (this.delay > 0) {
            this.delay -= 1;
            return;
        }
        const t = Date.now() * 0.001;
        const px = Math.sin(t * 0.7) * 0.2;
        const py = Math.sin(t * 0.3) * 0.2;
        this.model.position.x += (px - this.model.position.x) * 0.02;
        this.model.position.y += (py - this.model.position.y) * 0.02;

        const rz = Math.sin(t * 0.7) * 0.075;
        const ry = (Math.sin(t * 0.3) * 0.075) + MathUtils.degToRad(-90);
        this.model.rotation.z += (rz - this.model.rotation.z) * 0.02;
        this.model.rotation.y += (ry - this.model.rotation.y) * 0.02;
    }

    onResize() {
        this.windowHalfX = this.element.clientWidth * 0.5;
        this.windowHalfY = this.element.clientHeight * 0.5;
        this.scrollLimitY = this.element.clientHeight * 0.6;
    }

    _onHover(e) {
        const mouseX = (e.clientX - this.windowHalfX);
        const x = (-mouseX - this.camera.position.x) * 0.002;

        const mouseY = (e.clientY - this.windowHalfY);
        const y = (mouseY - this.camera.position.y) * 0.001;
        this.camera.position.x = x;
        this.camera.position.y = y;
        this.camera.lookAt(this.scene.position)
    }

    _onScroll() {
        if (!this.model) {
            return;
        }
        const scrollY = window.scrollY / this.scrollLimitY;
        const isScrollYChanged = scrollY !== this.prevScrollY;
        if (window.scrollY >= 0 && scrollY <= 1 && isScrollYChanged) {
            this.model.position.add(new Vector3(0, 0, (-(scrollY * 10) - this.model.position.z)))
            this.prevScrollY = scrollY;
        }
    }
}
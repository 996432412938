import $ from 'jquery'
import Util from '../util'

const NAME = 'footer'
const DATA_KEY = 'wr.footer'
const JQUERY_NO_CONFLICT = $.fn[NAME]

class Footer {
    constructor(element) {
        this._element = element;

        $('.nav-link', this._element).on('click', this._onPressNavLink.bind(this));
        $('.footer-top', this._element).on('click', this._onPressTopLink.bind(this));
    }

    _onPressTopLink(event) {
        event.preventDefault();
        Util.scrollBodyTo(0);
        return false;
    }

    _onPressNavLink(event) {
        var $target = $($(event.currentTarget).attr('href'));
        if ($target.length) {
            event.preventDefault();
            Util.scrollBodyTo($target.offset().top);
            return false;
        }
    }

    static _jQueryInterface(config) {
        return this.each(function () {
            const $element = $(this)
            let data = $element.data(DATA_KEY)

            if (!data) {
            data = new Footer(this)
            $element.data(DATA_KEY, data)
            }

            if (config === 'close') {
            data[config](this)
            }
        })
    }
}

$.fn[NAME] = Footer._jQueryInterface
$.fn[NAME].Constructor = Footer
$.fn[NAME].noConflict = () => {
  $.fn[NAME] = JQUERY_NO_CONFLICT
  return Footer._jQueryInterface
}
$(function() {
    $('.component-footer').footer();
});
import $ from 'jquery'
import ScrollMagic from 'scrollmagic'
import Util from '../util'

const NAME = 'header'
const DATA_KEY = 'wr.header'
const JQUERY_NO_CONFLICT = $.fn[NAME]

class Header {
    constructor(element) {
        this._element = element;
        this.$collapse = $('.collapse', this._element);
        
        this._create();
        this._init();
    }

    _onOpenMobileMenu() {
        $('body').addClass('no-scroll');
        $(this._element).addClass('mobile-navigation-collapsed');
    }

    _onCloseMobileMenu() {
        $('body').removeClass('no-scroll');
        $(this._element).removeClass('mobile-navigation-collapsed');
    }

    _onResize() {
        this._closeMobileMenu();
        this._changeMobileMenuHeight();
    }

    _onPressNavLink(event) {
        var $target = $($(event.currentTarget).attr('href'));
        if ($target.length) {
            event.preventDefault();
            this._closeMobileMenu();
            Util.scrollBodyTo($target.offset().top);
            return false;
        }
    }

    _create() {
        this.scene = new ScrollMagic.Scene({
            triggerElement: 'body',
            triggerHook: 0,
            offset: 5,
        })
            .setClassToggle(this._element, 'shadowed')
            .addTo(Util.getScrollController());

        this._changeMobileMenuHeight();
    }

    _init() {
        this.$collapse
            .on('show.bs.collapse', this._onOpenMobileMenu.bind(this))
            .on('hidden.bs.collapse', this._onCloseMobileMenu.bind(this));
        $('.nav-link', this._element).on('click', this._onPressNavLink.bind(this));
        $(window).on('resize', this._onResize.bind(this));
    }

    _changeMobileMenuHeight() {
        const breakPoint = Util.getWidthBreakPoint();
        const mobileNavigationHeight = Util.getNavigationHeight();
        const windowHeight = $(window).height();
        if (['xs', 'sm', 'md'].includes(breakPoint)) {
            this.$collapse.children('ul').css('height', windowHeight - mobileNavigationHeight);
        } else {
            this.$collapse.children('ul').removeAttr('style');
        }
    }

    _closeMobileMenu() {
        if (this.$collapse.hasClass('show')) {
            this.$collapse.collapse('hide');
        }
    }

    static _jQueryInterface(config) {
        return this.each(function () {
            const $element = $(this)
            let data = $element.data(DATA_KEY)

            if (!data) {
            data = new Header(this)
            $element.data(DATA_KEY, data)
            }

            if (config === 'close') {
            data[config](this)
            }
        })
    }
}

$.fn[NAME] = Header._jQueryInterface
$.fn[NAME].Constructor = Header
$.fn[NAME].noConflict = () => {
  $.fn[NAME] = JQUERY_NO_CONFLICT
  return Header._jQueryInterface
}
$(function() {
    $('.component-header').header();
});
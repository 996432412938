import {
    DirectionalLight,
    DirectionalLightHelper,
    Color,
} from 'three'
import _ from 'lodash'

const lights = [
    //Left light
    {
        color: new Color(0xffffff),
        intensity: 0.5,
        position: [-15, 0, 0],
        // debug: {
        //     size: 5,
        //     color: new Color(0xff0000)
        // },
        target: [0,0.5,0]
    },
    //Right light
    {
        color: new Color(0xffffff),
        intensity: 0.5,
        position: [15, 0, 0],
        // debug: {
        //     size: 5,
        //     color: new Color(0x00ff00)
        // },
        target: [0, 0.5, 0]
    },
    //Back light
    {
        color: new Color(0xadcaff),
        intensity: 0.01,
        position: [0, 4, -15],
        // debug: {
        //     size: 5,
        //     color: new Color(0x0000ff)
        // },
        target: [0,0.3,0]
    },
    //Top light
    {
        color: new Color(0xf0f8ff),
        intensity: 1.0,
        position: [-15, 15, 0],
        // debug: {
        //     size: 5,
        //     color: new Color(0x0ff000)
        // }
    },
    //Bottom light
    {
        color: new Color(0xecf1ff),
        intensity: 1.0,
        position: [0,-55,-55],
        // debug: {
        //     size: 5,
        //     color: new Color(0x800080)
        // },
        target: [0,0,-0.6]
    }
];

export default scene => {
    _.forEach(lights, configs => {
        const { color, intensity, position, debug } = configs;
        const light = new DirectionalLight(color, intensity);
        light.position.set(...position);

        if (configs.target) {
            light.target.position.set(...configs.target)
            light.target.updateMatrixWorld();
        }
        scene.add(light)
        scene.add(light.target)

        if (debug) {
            const lightDebuger = new DirectionalLightHelper(light, debug.size, debug.color);
            scene.add(lightDebuger);
        }
    });
}
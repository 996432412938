import $ from 'jquery'
import ScrollMagic from 'scrollmagic'
import 'scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min.js'

var scrollController;
const Util  = {
    getScrollController: function () {
        if (!scrollController) {
            scrollController = new ScrollMagic.Controller({
                // addIndicators: true
            });
        }
        return scrollController;
    },
    getNavigationHeight: function() {
        return $(window).width() > 991 ? 68 : 58;
    },
    scrollBodyTo: function(value) {
        var navigationOffset = $(window).width() > 991 ? 68 : 58;
        var toValue = value;
        if (value > navigationOffset) {
            toValue = value - navigationOffset;
        }
        $('html,body').animate({ scrollTop: toValue }, 'slow');
    },
    getWidthBreakPoint: () => {
        const width = $(window).width();
        if (width >= 1200) {
            return 'xl';
        }
        if (width >= 992) {
            return 'lg';
        }
        if (width >= 768) {
            return 'md';
        }
        if (width >= 576) {
            return 'sm';
        }
        return 'xs';
    }
}
export default Util;
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import ScrollMagic from 'scrollmagic'
import { TweenMax, TimelineMax } from 'gsap'
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap'
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

import 'popper.js'

import './css/global.scss'

import './components/contact/contact'
import './components/contact/contact.scss'

import './components/footer/footer'
import './components/footer/footer.scss'

import './components/intro/intro.scss'
import './components/intro/intro'

import './components/header/header.scss'
import './components/header/header'

import './components/services/services.scss'

import './components/about/about.scss'

import './components/work/work.scss'
import './components/work/work'

import './components/intro-video/intro-video'
import './components/intro-video/intro-video.scss'

import './components/3d/scene'
import './components/3d/scene.scss'

import './components/consent/consent'
import './components/consent/consent.scss'
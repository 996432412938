import $ from 'jquery'
import Util from '../util'
import videojs from 'video.js/dist/video.js'
import 'video.js/dist/video-js.css'

class IntroVideoPlugin extends videojs.getPlugin('plugin') {
    constructor(player, options) {
        super(player, options);

        this.controlBar = player.getChild('ControlBar');

        player.on('play', this._onPlay.bind(this));
        player.on('pause', this._onPause.bind(this));
    }

    _onPlay() {
        this.controlBar.removeClass('vjs-fade-in');
        this.controlBar.addClass('vjs-fade-out');
    }

    _onPause() {
        this.controlBar.removeClass('vjs-fade-out');
        this.controlBar.addClass('vjs-fade-in');
    }
}
videojs.registerPlugin('intro_video_plugin', IntroVideoPlugin);

class CtaButton extends videojs.getComponent('Button') {
    constructor(player, options) {
        super(player, options);
        this.setAttribute('id', 'video-cta');
    }

    createEl(tag, props = {}, attributes = {}) {
        return super.createEl(
            'button',
            { ...props,
                innerHTML: "Ready to get started?",
                className: 'cta'
            },
            {...attributes, type: 'button' }
        );
    }

    handleClick(event) {
        var $target = $('#contact');
        if ($target.length) {
            if (document.fullscreenElement) {
                document.exitFullscreen()
                    .then(() => {
                        setTimeout(() => {
                            Util.scrollBodyTo($target.offset().top)
                        }, 500);
                    })
                    .catch((err) => console.error(err))
            } else {
                Util.scrollBodyTo($target.offset().top);
            }
        }
        dataLayer.push({'event':'cta', 'value': 'intro video cta'});
    }
}
videojs.registerComponent('CtaButton', CtaButton);

const NAME = 'video_intro'
const DATA_KEY = 'wr.video_intro'
const JQUERY_NO_CONFLICT = $.fn[NAME]

class VideoIntro {
    constructor(element) {
        this._element = element;
        this.$element = $(element);

        this.player = videojs('video-intro', {
            controls: true,
            controlBar: {
                pictureInPictureToggle: false,
                remainingTimeDisplay: false
            },
            width: 800,
            height: 450

        }, this._onCreate.bind(this));
    }

    _onCreate() {
        this.player.addChild('CtaButton');
        this.player.intro_video_plugin();
        this.$ctaButton = $('.cta', this._element);

        this.player.on('play', this._onPlay.bind(this));
        var markers = {
            '10': true,
            '25': true,
            '50': true,
            '75': true
        };
        this.player.on('timeupdate', function() {
            var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
            if (markers[percentPlayed]) {
                markers[percentPlayed] = false;
                dataLayer.push({ event: 'video_progress', video_title: 'Intro', video_percent: percentPlayed, video_current_time: Math.round(this.currentTime()) });
            }
        });
        this.player.on('pause', () => {
            dataLayer.push({ event: 'video_progress', video_title: 'Intro', video_percent: 'pause', video_current_time: Math.round(this.player.currentTime()) });
        });
        this.player.on('ended', this._onEnded.bind(this));
    }

    _onPlay() {
        dataLayer.push({ event: 'video_start', video_title: 'Intro', video_current_time: Math.round(this.player.currentTime()) });
        $('.heading', this._element).hide();
        this.$ctaButton.hide();
    }

    _onEnded() {
        dataLayer.push({ event: 'video_complete', video_title: 'Intro' });

        this.$element.addClass('replay');
        var $poster = $('.vjs-poster', this._element)
        $poster.fadeIn(1000, 'swing', () => {
            $poster.css('display', '');
            this.player.load();
            this.$ctaButton.fadeIn(1000, 'swing');
        });
    }

    static _jQueryInterface(config) {
        return this.each(function () {
            const $element = $(this)
            let data = $element.data(DATA_KEY)

            if (!data) {
                data = new VideoIntro(this)
                $element.data(DATA_KEY, data)
            }

            if (config === 'close') {
                data[config](this)
            }
        })
    }
}

$.fn[NAME] = VideoIntro._jQueryInterface
$.fn[NAME].Constructor = VideoIntro
$.fn[NAME].noConflict = () => {
  $.fn[NAME] = JQUERY_NO_CONFLICT
  return VideoIntro._jQueryInterface
}

$(function() {
    $('#intro-video').video_intro();
});
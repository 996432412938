import $ from 'jquery'
import {
    WebGLRenderer,
    Scene,
    Color,
    PerspectiveCamera,
    // AxesHelper,
    ACESFilmicToneMapping,
    sRGBEncoding,
    PMREMGenerator,
    UnsignedByteType,
    MathUtils
} from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import Stats from 'stats.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

import Util from '../util';
import Animation from './animation';
import lights from './lights';
import { dimensions } from './util';

const NAME = 'scene_3d'
const DATA_KEY = 'wr.scene_3d'
const JQUERY_NO_CONFLICT = $.fn[NAME]

class Scene3D {
    constructor(element) {
        this.tick = this.tick.bind(this);
        const { width, height } = dimensions();
        this._element = element;
        this._updateElement();

        const camera = this.camera = new PerspectiveCamera( 45, width / height, 0.25, 20 );
        camera.position.set( 0, 0, 5 );

        this.animation = new Animation(element);
        this.animation.camera = camera;

        const scene = this.scene = new Scene();
        this.scene.background = new Color(0xdcdedf);
        this.animation.scene = scene;
        
        new RGBELoader()
            .setDataType(UnsignedByteType)
            .setPath( './' )
            .load( 'bg-light-gray.hdr', ( texture ) => {

                const envMap = pmremGenerator.fromEquirectangular( texture ).texture;

                scene.background = envMap;
                scene.environment = envMap;

                texture.dispose();
                pmremGenerator.dispose();

                const loader = new GLTFLoader().setPath( './' );
                loader.load( 'scene.gltf', ( gltf ) => {

                    gltf.scene.traverse(( child ) => {
                        if ( child.isMesh ) {
                            this.animation.model = child;
                            child.rotation.y = MathUtils.degToRad(-90);
                            child.rotation.x = MathUtils.degToRad(7.5);
                        }

                    } );
                    gltf.scene.position.y = 1
                    this.scene.add( gltf.scene );
                    this.tick();
                });
            })
        

        const renderer = this.renderer = new WebGLRenderer( { antialias: true } );
        renderer.setClearColor(new Color(0xdcdedf))
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( width, height );
        renderer.toneMapping = ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1;
        renderer.outputEncoding = sRGBEncoding;
        element.appendChild( renderer.domElement );

        const pmremGenerator = new PMREMGenerator( renderer );
        pmremGenerator.compileEquirectangularShader();

        lights(this.scene);

        this.renderer.render( this.scene, this.camera );

        $(window).on('resize', this._onWindowResize.bind(this));

        // const controls = new OrbitControls( camera, renderer.domElement );
        // controls.addEventListener( 'change', this.render.bind(this) ); // use if there is no animation loop
        // controls.minDistance = 2;
        // controls.maxDistance = 10;
        // controls.target.set( 0, 0, - 0.2 );
        // controls.update();

        // const axesHelper = new AxesHelper(5);
        // scene.add(axesHelper);

        // const stats = this.stats = new Stats();
        // stats.showPanel(0);
        // document.body.appendChild( stats.dom );
    }

    _onWindowResize() {
        this._updateElement();
        const { width, height } = dimensions();
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize( width, height );
        this.animation.onResize();
    }

    _updateElement() {
        const { width, height } = dimensions();
        this._element.style.width = `${width}px`;
        this._element.style.height = `${height}px`;
    }

    tick() {
        setTimeout(() => {
            requestAnimationFrame(this.tick);
        }, 1000 / 30 );
        // this.stats.begin();
        this.renderer.render( this.scene, this.camera );
        this.animation.update();
        // this.stats.end();
    }

    static _jQueryInterface(config) {
        return this.each(function () {
            const $element = $(this)
            let data = $element.data(DATA_KEY)

            if (!data) {
                data = new Scene3D(this)
                $element.data(DATA_KEY, data)
            }

            if (config === 'close') {
                data[config](this)
            }
        })
    }
}

$.fn[NAME] = Scene3D._jQueryInterface
$.fn[NAME].Constructor = Scene3D
$.fn[NAME].noConflict = () => {
  $.fn[NAME] = JQUERY_NO_CONFLICT
  return Scene3D._jQueryInterface
}

$(function() {
    $('#3d-holder').scene_3d();
    $('.component-3d #animation-button').on('click', (event) => {
        var $target = $($(event.currentTarget).attr('href'));
        if ($target.length) {
            event.preventDefault();
            Util.scrollBodyTo($target.offset().top);
            return false;
        }
    });
});